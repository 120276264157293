import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NotFoundCause } from '@core/interfaces/not-found';
import { HubAuthService } from '@core/services/hub-auth/hub-auth.service';
import { select } from '@ngxs/store';
import { AppState } from '@core/store/application/application.state';
import { MainNavbarComponent } from '@shared/features/hub-main-navbar/main-navbar.component';
import { NavbarState } from '@core/store/navbar/navbar.state';
import { HubButtonModule } from '@shared/components/hub-button/hub-button.module';

@Component({
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MainNavbarComponent, HubButtonModule],
})
export class PageNotFoundComponent implements OnInit {
  closed = select(NavbarState.closed);
  mode = select(AppState.mode);

  notFoundCause = NotFoundCause;

  cause = '';

  constructor(
    private route: ActivatedRoute,
    private hubAuthService: HubAuthService,
  ) {}

  ngOnInit(): void {
    const { cause } = this.route.snapshot.queryParams;
    this.cause = Object.values(NotFoundCause).includes(cause) ? cause : NotFoundCause.PAGE;
  }

  logout(): void {
    this.hubAuthService.logout();
  }
}
