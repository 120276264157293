<div class="container-fluid hub-h-100">
  <div class="row hub--container">
    <div class="hub-navbar" [class.hub-navbar-closed]="closed()">
      <hub-main-navbar />
    </div>
    <div class="col hub-p-0 hub-h-100 hub-page--container">
      <div
        class="hub-d-flex hub-flex-column hub-justify-content-center hub-align-items-center hub-h-100">
        @switch (cause) {
          @case (notFoundCause.PAGE) {
            <h1 class="hub-text-secondary hub-tt-uppercase">404 {{ cause }} NOT FOUND</h1>
            <img src="../../../../assets/images/svg/not-found-page.svg" alt="not found" />
          }
          @case (notFoundCause.ACCOUNT) {
            <h1 class="hub-text-secondary hub-tt-uppercase">ACCOUNT NOT FOUND</h1>
            <p class="hub-text-center">
              Oops.. it looks like we couldn’t find any account to sign you in <br />
              Please contact your Admin/Support team <br />
              Or you can Sign Up for a new OL Hub account
            </p>
            <hub-button (clicked)="logout()">Sign in as a different user</hub-button>
            <img src="../../../../assets/images/svg/dino.svg" alt="not found" />
          }
          @case (notFoundCause.SSO_ONLY) {
            <h1 class="hub-text-secondary hub-tt-uppercase">SSO ONLY</h1>
            <p class="hub-text-center">
              The admin of your organization has enabled SSO, <br />
              Username & Password authentication is disabled.
            </p>
            <hub-button (clicked)="logout()">Back</hub-button>
            <img src="../../../../assets/images/svg/dino.svg" alt="not found" />
          }
        }
      </div>
    </div>
  </div>
</div>
